<template>
    <div>
        <center>
            <h4>TopUp Kuota</h4>
        </center>
        <br>
        <form v-on:submit.prevent="buy" enctype="multipart/form-data">
            <label> Jumlah Kuota Respon (Rp 20 / Respon)</label>
            <money class="form-control" v-model="form.quota" v-bind="money"></money>
            <br>
            <hr>
            <div class="float-right">
                <table style="border: none;">
                    <tr>
                        <td>Total Harga</td>
                        <td>&nbsp;:&nbsp;</td>
                        <td><b>{{ formatRupiah(form.quota * 20) }}</b></td>
                    </tr>
                    <tr>
                        <td>Saldo </td>
                        <td>&nbsp;:&nbsp;</td>
                        <td>{{ formatRupiah(user_balance) }}</td>
                    </tr>
                </table>
                <br>
            </div>
            <!-- <label for="">Pesan Registrasi</label>
            <CustomTextArea v-model="form.message"></CustomTextArea>
            <i>Anda bisa memanggil variabel menggunakan [] (Bracket)</i>
            <br>
            <i>Variabel Tersedia : {{ available_variable }}</i>
            <br> -->
            <br>
            <button class="btn btn-success btn-block">Beli</button>
        </form>
    </div>
</template>

<script>
    import { Money } from 'v-money'

    export default {
        props: ['id', "dataset_id"],
        components: {
            Money
        },
        data() {
            return {
                form: {
                    quota: null
                },
                money: {
                    thousands: '.',
                    precision: 0,
                },
                user_balance: 0
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }

            this.$root.$on("toggle_modal", this.fnToggleModal);

            this.fetchUser()
        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            onFileChange(e) {
                this.form.avatar = e.target.files[0]
            },
            async buy(){
                try{
                    let create = await this.$store.dispatch('chatgpt_topup', { chatgpt_id: this.id, quota: parseInt(this.form.quota) } )
                    this.$root.$emit("toggle_modal", "topup")
                    this.$toast.success(create.data.message)
                    this.$root.$emit("refresh_quota", true)
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            },
            formatRupiah(numb) {
                const format = numb.toString().split('').reverse().join('');
                const convert = format.match(/\d{1,3}/g);
                const rupiah = 'Rp ' + convert.join('.').split('').reverse().join('')
                return rupiah
            },
            async fetchUser() {
                try {
                    let user_data = await this.$store.dispatch('user_detail')
                    this.user_balance = user_data.data.data.user.balance
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                }
            },
        },
    };
</script>

<style scoped>
    

    table {
        border: 1px solid;
    }
</style>